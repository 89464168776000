<style lang="scss" scoped>
.project {
  .large-img {
    width: 100%;
    object-fit: cover;
    height: 250px;
    background: whitesmoke;
    border-radius: 16px;
    @media (min-width: 992px) {
      height: 480px;
    }
  }

  .card-text {
    @media (min-width: 992px) {
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
    }
  }
  .project-title {
    font-size: 48px;
    font-weight: 600;
    line-height: 59.14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    ::v-deep span {
      font-size: 48px;
      font-weight: 300;
      line-height: 59.14px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }

  .project-tag {
    background: var(--primary);
    border-radius: 99px;
    color: #ffffff;
    padding: 8px 16px;
    display: inline-block;
    text-transform: uppercase;
  }
  .tag {
    position: absolute;
    text-align: center;
    top: 0;
    color: #ffffff;
    padding: 12px 24px;
    border-radius: 16px;

    font-size: 24px;
    font-weight: 500;
    line-height: 29.57px;
    letter-spacing: 0.05em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  ::v-deep .icon {
    height: 20px;
    width: 20px;
  }

  &.card-left {
    @media (min-width: 992px) {
      .card-image {
        order: 0;
      }
      .card-text {
        order: 1;
      }
    }
    .tag {
      border-top-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  &.card-right {
    @media (min-width: 992px) {
      .card-image {
        order: 1;
      }
    }
    .card-text {
      order: 0;
    }
    .tag {
      right: 0;
      border-top-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}
</style>

<template>
  <div class="row project mb-24 mb-lg-80" :class="`card-${direction}`">
    <div class="col-12 card-image" :class="direction === 'left' ? 'col-lg-7' : 'col-lg-7 offset-lg-1'">
      <div class="position-relative">
        <img v-if="project.pictures.length > 0" class="w-100 mb-24 mb-lg-0 large-img" :src="project.pictures[0].urlXXL" alt="" />
        <div v-else class="w-100 mb-24 mb-lg-80 large-img"></div>
        <div class="tag bg-primary" v-if="project.purposeStatus.id === 3">UITVERKOCHT</div>
        <div class="tag bg-secondary" v-if="project.purposeStatus.id === 5">Bod aanvaard</div>
      </div>
    </div>

    <div class="col-12 card-text" :class="{ 'offset-lg-1 col-lg-4': direction === 'left', 'col-lg-4': direction === 'right' }">
      <div class="">
        <p class="mb-8 project-tag" v-if="project.purposeStatus.id === 1">Beschikbaar</p>
        <p class="mb-8 project-tag" v-if="project.purposeStatus.id === 12">In optie</p>
        <p class="mb-8 project-tag" v-if="project.purposeStatus.id === 3">Verkocht</p>

        <h3 class="mb-24 d-none d-lg-block project-title" v-html="`${project.name} <span>in ${project.city}</span>`"></h3>
        <p v-if="project.acf && project.acf.intro" v-html="project.acf.intro" class="text-gray"></p>
        <p class="mb-56 mb-lg-80 d-flex flex-row text-primary">
          <span v-html="svg('marker', 'icon icon-primary mr-8')"></span>
          {{ project.address }} {{ project.number }}, {{ project.zip }} {{ project.city }}
        </p>
      </div>
      <div class="mb-24 mb-lg-80 d-lg-none">
        <h3 class="h4 mb-24" v-html="project.name"></h3>
      </div>
      <div class="">
        <a class="btn btn-primary-v2" :href="route('nieuwbouw.show', { slug: project.id + '-' + project.city + '-' + project.name })"> Bekijk het project </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project: {
      required: true,
    },
    direction: {
      type: String,
      default: 'left',
    },
  },

  mounted() {
    console.log(this.project);
  },
};
</script>
