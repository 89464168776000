<style scoped lang="scss">
.estate-line {
  border-radius: 7px;
  background: rgba(245, 245, 245, 1);
  color: rgba(165, 165, 165, 1);
  padding: 4px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  gap: 2px;

  &.epc-line {
    padding-left: 6px;
    padding-right: 6px;
    background: rgba(245, 245, 245, 1);
    color: rgba(165, 165, 165, 1);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 24px;
    position: relative;
    font-weight: 600;
    &:not(.bw).A {
      background: var(--primary);
    }
    &:not(.bw).B {
      background: #d0d201;
    }
    &:not(.bw).C {
      background: #f3d700;
    }
    &:not(.bw).D {
      background: #fbba3d;
    }
    &:not(.bw).E {
      background: #ef7a1b;
    }
    &:not(.bw).F {
      background: #de3031;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 12px solid #fff;
      border-bottom: 12px solid #fff;
      border-left: 12px solid rgba(245, 245, 245, 1);
      right: -12px;
      position: absolute;
      top: 0;
    }
    &:not(.bw).A:after {
      border-left: 12px solid var(--primary);
    }
    &:not(.bw).B:after {
      border-left: 12px solid #d0d201;
    }
    &:not(.bw).C:after {
      border-left: 12px solid #f3d700;
    }
    &:not(.bw).D:after {
      border-left: 12px solid #fbba3d;
    }
    &:not(.bw).E:after {
      border-left: 12px solid #ef7a1b;
    }
    &:not(.bw).F:after {
      border-left: 12px solid #de3031;
    }
  }

  ::v-deep .card-icon {
    height: 16px;
    &.stroke-gray {
      path {
        stroke: rgba(165, 165, 165, 1);
      }
    }
    &.fill-none {
      path {
        fill: transparent;
      }
    }
    &.fill-gray {
      path {
        fill: rgba(165, 165, 165, 1);
      }
    }
  }
}
</style>

<template>
  <div class="estate-line" v-if="data" :class="[type === 'epc' ? `${data} epc-line bw` : '']">
    <template v-if="type === 'rooms'">
      <span v-html="svg('Slaapkamer', 'card-icon fill-gray')"></span>
      {{ data }}
    </template>
    <template v-else-if="type === 'ground'">
      <span v-html="svg('PerceelOppervlakte', 'card-icon stroke-gray fill-none')"></span>
      <span>{{ Math.round(data) }}m<sup>2</sup></span>
    </template>
    <template v-else-if="type === 'area'">
      <span v-html="svg('house', 'card-icon stroke-gray')"></span>
      <span>{{ Math.round(data) }}m<sup>2</sup></span>
    </template>
    <template v-else-if="type === 'box'">Blok {{ data }}</template>
    <template v-else-if="type === 'floor'">Vloer {{ data }}</template>
    <template v-else-if="type === 'epc'">{{ data }}</template>
    <template v-else></template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      required: true,
    },
    type: {
      type: String,
      default: 'other',
    },
  },
};
</script>
