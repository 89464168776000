<style lang="scss" scoped>
::v-deep {
  .tns-controls {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    left: 0;
    width: 100%;
    z-index: 1;
    pointer-events: none;
    justify-content: space-between;
    button {
      transition: opacity 0.15s ease-in-out;
      &:disabled {
        opacity: 0;
        visibility: hidden;
      }
      img {
        height: 40px;
        width: 40px;
        background: #fff;
        transition: background 0.15s ease-in-out;
        object-fit: none;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
        border-radius: 16px;
        @media (min-width: 768px) {
          height: 56px;
          width: 56px;
        }
        &:hover {
          background: #fefefe;
        }
      }
    }
  }
  .tns-outer [aria-controls],
  .tns-outer [data-action] {
    cursor: pointer;
    background: none;
    box-shadow: unset;
    border: none;
    pointer-events: all;
    z-index: 2;
    padding: 15px;
    &:focus {
      outline: none;
    }
  }
  .vgs__container {
    @media (min-width: 768px) {
      margin-top: auto;
      bottom: 132px;
      top: 0;
      margin-bottom: auto;
    }
    @media (min-width: 1440px) {
      height: calc(100vh - 200px);
      width: calc(100vw - 150px);
      background-color: #fff0;
    }
  }
}

</style>

<template>
  <div ref="slider">
    <div class="container" ref="fakeTainer"></div>
    <div class="slider" v-if="!loading">
      <div v-for="(image, key) in renderedImages" :key="key" :style="{ height: `${image.preffered_height}px` }">
        <img :style="{ width: `${image.preffered_width}px`, height: `${image.preffered_height}px` }" :class="`image-${key}`" :data-src="image.src" @click="index = key" class="lazyload rounded-lg" />
      </div>
    </div>
    <vue-gallery-slideshow v-if="!loading" :images="renderedImages" :index="index" @close="index = null"></vue-gallery-slideshow>
  </div>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider';
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
  components: {
    VueGallerySlideshow,
  },

  props: {
    images: {
      required: true,
    },
    size: {
      default: 'large',
      type: String,
      required: false,
    },
    urlProperty: {
      required: false,
      type: String,
      default: 'UrlXXL',
    },
    prefferedHeight: {
      required: false,
      default: 662,
    },
  },
  data() {
    return {
      tns: null,
      renderedImages: [],
      loading: true,
      index: null,
    };
  },
  mounted() {
    this.renderImages();
  },
  methods: {
    initTns() {
      let self = this;
      this.$nextTick(() => {
        let stagePadding = (window.innerWidth - this.$refs.fakeTainer.offsetWidth) / 2 - 10 || 85;
        this.images.length <= 3 ? (stagePadding = 0) : (stagePadding = stagePadding);

        this.tns = tns({
          container: this.$refs.slider.querySelector('.slider'),
          items: this.images.length <= 3 ? 1 : 1.15,
          gutter: 24,
          touch: true,
          autoWidth: true,
          nav: false,
          loop: false,
          rewind: true,
          center: false,
          preventScrollOnTouch: 'auto',
          controls: this.images.length <= 3 ? false : true,
          controlsText: ["<img src='/images/arrow-prev.svg'/>", "<img src='/images/arrow-next.svg'/>"],
          swipeAngle: false,
          responsive: {
            960: {
              edgePadding: stagePadding,
              arrowKeys: true,
              mouseDrag: true,
            },
          },
        });
      });
    },
    renderImages() {
      this.loading = true;
      let promises = [];
      let self = this;
      let preffered_height = this.prefferedHeight;
      let preffered_width = this.$refs.fakeTainer.offsetWidth;
      if (window.innerWidth < 1200) preffered_height = 550;
      if (window.innerWidth < 960) preffered_height = 400;
      if (window.innerWidth < 768) preffered_height = 200;

      if (this.tns) {
        this.tns.destroy();
      }

      this.images.forEach((image, key) => {
        promises.push(
          new Promise((resolve, reject) => {
            let i = new Image();
            let ordinal = image.ordinal;
            i.src = self.deepProperty(image);
            i.onload = function (e) {
              let ratio = self.calculateAspectRatioFit(i.width, i.height, preffered_width, preffered_height);
              const image = {
                src: i.src,
                url: i.src,
                preffered_width: ratio.width,
                preffered_height: preffered_height,
                ordinal: ordinal,
                key: key,
              };
              self.renderedImages.push(image);
              resolve();
            };
          }),
        );
      });

      Promise.all(promises).then((r) => {
        this.loading = false;
        const unique = [...new Set(this.renderedImages.map((item) => item.ordinal))];
        if (unique.length === 1) {
          this.renderedImages.sort((a, b) => a.key - b.key);
        } else {
          this.renderedImages.sort((a, b) => a.ordinal - b.ordinal);
        }
        this.initTns();
      });
    },
    calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
      var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

      return { width: srcWidth * ratio, height: srcHeight * ratio };
    },
    deepProperty(image) {
      let s = this.urlProperty.split('.');

      let obj = image[s.shift()];

      while (obj && s.length) obj = obj[s.shift()];

      return obj;
    },
  },
};
</script>
