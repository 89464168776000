<style scoped>
.estate-price {
  border-radius: 50px;
  background: var(--primary);
  border: 4px solid #fff;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.25px;
  letter-spacing: 0.05em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 8px 16px;
}
</style>

<template>
  <h4 v-if="!displayPrice" class="estate-price" :class="propertySpecialType === 'gold' ? 'gold-price' : ''">Prijs op aanvraag</h4>
  <h5 v-else-if="price && [1, 2].includes(statusId) && ![3, 4, 5, 6, 16].includes(purposeStatusId)" class="estate-price" :class="propertySpecialType === 'gold' ? 'gold-price' : ''">{{ formatPrice(price, 'EUR') }} <template v-if="[2].includes(purposeStatusId)">/ maand</template></h5>
  <h5 v-else-if="purposeStatusId === 2" class="estate-price">Verhuurd</h5>
  <h5 v-else-if="purposeStatusId === 3" class="estate-price">Verkocht</h5>
  <h5 v-else-if="[12, 13, 21].includes(purposeStatusId)" class="estate-price">In optie</h5>
  <h5 v-else-if="[5, 6, 16].includes(purposeStatusId)" class="estate-price">Bod aanvaard</h5>
  <h5 v-else-if="isProject" class="estate-price">Project</h5>
</template>

<script>
export default {
  props: {
    displayPrice: {
      type: Boolean,
      required: false,
    },
    statusId: {
      type: Number,
      required: true,
    },
    purposeStatusId: {
      type: Number,
      required: true,
    },
    price: {
      type: Number,
      required: false,
    },
    isProject: {
      type: Boolean,
      required: false,
    },
    propertySpecialType: {
      type: String,
      required: false,
    },
  },
};
</script>
