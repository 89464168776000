<style lang="scss" scoped>
.banner {
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: relative;
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), url(/images/banner-projects.png);
  background-position: bottom;
  @media (min-width: 768px) {
    padding: 120px;
  }
}
.text-title {
  margin-bottom: 20px;
}
.text-subtitle {
  margin-bottom: 40px;
}
::v-deep .checkmark {
  position: absolute;
  right: 0px;
  bottom: -58px;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}
</style>

<template>
  <div class="banner">
    <div class="container">
      <h2 class="text-title">Ben je een <span class="text-primary">verkoper</span>?</h2>
      <div class="text-subtitle">Een nieuwbouwproject commercialiseren kan digitaler, efficiënter én goedkoper.</div>
      <a class="btn btn-primary border-50" href="/nieuwbouw/verkopen">Bekijk de mogelijkheden</a>
      <span v-html="svg('checkmark', 'checkmark')"></span>
    </div>
  </div>
</template>

<script>
export default {};
</script>
