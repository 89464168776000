<style lang="scss" scoped>
.estate-card {
  cursor: default;
  text-decoration: none;
  display: block;
  border: 1px solid #f7f7f7;
  background-color: #ffffff;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 0;

  .estate-title {
    font-size: 16px;
    line-height: 1.2;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;

    .text-normal{
	text-align: left;
	font-weight: 300;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;

    }
  }

  .wrapped-line{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 135px;
  }

  .estate-price{
	position: absolute;
	right: 32px;
	top: -20px;
  }

  &:not(.nc) {
    cursor: pointer;
  }
  &.nc{
    cursor: default;
  }

  .estate-image {
    height: 224px;
    width: 100%;
    position: relative;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 16px;
   	border-top-right-radius: 16px;
    }
  }

  .gold-price {
    color: #bba876 !important;
  }

  .estate-footer {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    .icon {
      height: 20px;
      width: 20px;
    }

  }
}
</style>
<template>
  <component :is="[3, 4, 5, 6, 16].includes(data.purposeStatus.id) ? 'div' : 'a'" v-if="data" :href="computedRoute" class="estate-card" :class="[wrapperClass, { nc: [3, 4, 5, 6, 16].includes(data.purposeStatus.id) }]">
    <div class="estate-image">
      <img :data-srcset="imageStyle" class="lazyload" />
      <estate-special-label :estate="data"></estate-special-label>
    </div>

    <div class="d-flex flex-column">
      <div class="row">
        <div class="col-12 d-flex justify-content-start align-items-center position-relative">
            <EstatePrice :displayPrice="data.displayPrice" :statusId="data.status.id" :purposeStatusId="data.purposeStatus.id" :price="data.price" :isProject="data.is_project" :propertySpecialType="propertySpecialType ? propertySpecialType : ''" />
        </div>
      </div>

      <div class="row p-16">
        <div class="estate-footer col-12 d-flex ">
            <EstatePropertyLine type="rooms" :data="data.rooms" />
            <EstatePropertyLine type="ground" :data="data.groundArea" />
            <EstatePropertyLine type="area" :data="data.area" />
            <EstatePropertyLine v-if="data.is_project" type="box" :data="data.box" />
            <EstatePropertyLine v-if="data.is_project" type="floor" :data="data.floor" />
            <EstatePropertyLine type="epc" :data="epcClass" />
        </div>
      </div>

      <div class="row pb-16 px-16">
	<div class="col-12 d-flex">
            <h5 v-if="data.parentId" class="estate-title text-secondary h6">{{ data.name }}</h5> 
            <h5 v-else-if="!data.project_id" class="estate-title text-secondary h6">
		<span v-if="data.subCategory.id" class="">
		    {{ formatSubType(data.subCategory.id) }}
		</span>
		<span v-else-if="data.category.id" class="">
		    {{ formatType(data.category.id) }}
		</span>
		<span v-if="[1].includes(data.purposeStatus.id)">te koop</span>
		<span v-else-if="[2].includes(data.purposeStatus.id)">te huur</span>
		<span class="text-normal">in {{ data.city }}</span>
	    </h5> 
            <h5 v-else class="estate-title text-secondary h6">
                {{ data.description_title }}
            </h5>
        </div>
	<div class="col-12">
		<div>
		<span v-if="data.address" class="text-primary soft-underline">{{  data.address }} {{  data.number }}</span>
	    </div>
	</div>
      </div>
    </div>
  </component :is="[5, 6, 16].includes(data.purposeStatus.id) ? 'div' : 'a'">
</template>

<script>
import estateCard from '@/mixins/estate-card';
import estateSpecialLabel from '@/components/estate-special-label'
import EstatePrice from './estate-price.vue';

export default {
  mixins: [estateCard],
  components: {
    estateSpecialLabel
  },
};
</script>
