<template>
  <div :class="wrapperClass" ref="slider">
    <div v-for="office in offices" :key="office.id" data-aos="fade-up">
      <div class="office-card">
        <a class="office-image" :style="`background-image: url(${office.acf.image.url});  padding-top: 66%;`" :href="office.relative_link"></a>
        <div class="office-text">
          <a class="office-tag" :href="`tel:${office.acf.phone}`">{{ office.acf.phone }}</a>
          <h5 class="mb-16 office-title" v-html="`<span>Copandi</span> <span class='text-normal'> ${office.acf.title}</span>`"></h5>
          <a class="text-gray office-address soft-underline mb-18" :href="`https://www.google.com/maps/place/${office.acf.location.lat},${office.acf.location.lng}`" target="_blank">{{ office.acf.location.street_name }}, {{ office.acf.location.street_number }}</a>
          <a :href="office.acf.call_to_action " target="_blank" class="btn btn-block btn-sm btn-primary-v2">Plan een afspraak</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider';

export default {
  props: {
    wrapperClass: {
      default: 'mb-0',
    },
    offices: {
      default: () => [],
    },
  },

  data() {
    return {
      tns: null,
    };
  },

  mounted() {
    this.initSlider();
  },

  methods: {
    initSlider() {
      const self = this;
      let width = self.$refs.slider.clientWidth;
      this.$nextTick(() => {
        self.tns = tns({
          container: self.$refs.slider,
          gutter: 32,
          controls: false,
          touch: true,
          nav: false,
          loop: false,
          rewind: true,
          preventScrollOnTouch: 'auto',
          items: 1,
          fixedWidth: width * 0.9,
          autoWidth: false,
          responsive: {
            960: {
              fixedWidth: false,
              autoWidth: true,
              items: 3,
              arrowKeys: true,
              mouseDrag: true,
            },
          },
        });
      });
    },
  },

  computed: {
    multiLineClass() {
      return 'col-12 col-lg-6 col-xl-3 mb-grid';
    },
  },
};
</script>
